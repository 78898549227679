body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.underline-input {
  border: none !important;
  border-bottom: 1px solid #ced4da !important; /* Линия по умолчанию */
  border-radius: 0 !important;
  padding: 5px 0 !important;
  outline: none !important;
  transition: border-bottom-color 0.3s ease !important;
}

.underline-input:focus {
  border-bottom-color: #1c7ed6 !important; /* Линия при фокусе */
}
