/* Основные стили для календаря */
.react-calendar {
  width: 100vw; /* Ширина на весь экран */
  max-width: 100%; /* Ограничение ширины, чтобы не вылезал за границы */
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  padding: 10px;
  border: none;
}

.react-calendar__tile {
  position: relative;
  padding: 20px 0; /* Добавляем больше пространства */
  text-align: center;
  font-size: 16px;
  font-weight: normal; /* Обычный текст для всех дат */
  transition: background 0.3s ease;
  border-radius: 50%; /* Круглая форма для выделенной даты */
  font-family: 'Inter', sans-serif; /* Применяем шрифт для всех элементов */
}

.react-calendar__tile:enabled:hover {
  background-color: #e0eaff;
}

.react-calendar__tile--now {
  background: none; /* Убираем желтый фон */
  font-weight: bold; /* Оставляем жирный текст */
  text-decoration: underline;
  color: #0a74da;
}

.react-calendar__tile--now:enabled:hover {
  background-color: #e0eaff;
}

.react-calendar__tile--active {
  background: #0a74da;
  color: white;
  border-radius: 50%; /* Круглая форма для выбранной даты */
}

.react-calendar__tile--active:enabled:hover {
  background: #1b6dc5;
}

.react-calendar__navigation button {
  color: #0a74da;
  min-width: 44px;
  background: none;
  font-size: 18px;
  margin-top: 8px;
  cursor: pointer;
  border: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e0eaff;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

/* Стили для текста под датами */
.event-count {
  font-size: 0.8em;
  position: absolute;
  bottom: 0px; /* Ровно под датой */
  left: 50%;
  transform: translateX(-50%);
}

/* Цвета для разных значений */
.event-count-green {
  color: rgb(28, 175, 28);
}

.event-count-red {
  color: rgb(214, 50, 50);
}

.event-count-gray {
  color: gray;
}

.event-count-white {
  color: rgb(241, 241, 241);
}


/* Для маленьких экранов */
@media (max-width: 600px) {
  .react-calendar__tile {
    padding: 15px 0;
    font-size: 14px;
    font-family: 'Inter', sans-serif; /* Применяем шрифт для всех элементов */
  }
}
